import React from "react";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";

const ContentContainerNew = ({
  children,
  containerClassName,
  className,
  image,
  headerText,
}) => {
  const bgImage = convertToBgImage(getImage(image));
  return (
    <div
      className={`flex items-center justify-center ${
        containerClassName !== undefined
          ? containerClassName
          : "w-full min-w-full"
      }`}
    >
      {image !== undefined ? (
        <BackgroundImage
          {...bgImage}
          alt="Container"
          className={`${
            className !== undefined ? className : ""
          } container`}
        >
          {children}
        </BackgroundImage>
      ) : (
        <div
          className={`${className !== undefined ? className : ""} container`}
        >
          {
            <>
              {headerText && (
                <div className="bg-gray1 py-7 flex flex-col items-center justify-center">
                  <h2>{headerText}</h2>
                </div>
              )}
              {children}
            </>
          }
        </div>
      )}
    </div>
  );
};

export default ContentContainerNew;
