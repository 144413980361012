import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import ContentContainer from "../../../components/container/ContentContainer";
import ContentContainerNew from "../../../components/container/ContentContainerNew";
import ContactExpert from "../../../components/controls/ContactExpert";
import Header from "../../../components/sections/Header";
import HeadData from "../../../data/HeadData";
import { Link, useStaticQuery, graphql } from "gatsby";
import TitleSubDiv from "../../../components/controls/TitleSubDiv";
import ButtonAction from "../../../components/controls/ButtonAction";
import Layout from "../../../components/layout/Layout";

const PreFinishedWoodPanels = () => {
  const heroImage = useStaticQuery(graphql`
    {
      backGround: file(relativePath: { eq: "ridgemont-collection-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
          )
        }
      }
      sandDrift: file(
        relativePath: { eq: "pre-finished-wood-panels/ridgemont-collection/sand-drift.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
          )
        }
      }
      riverStone: file(
        relativePath: { eq: "pre-finished-wood-panels/ridgemont-collection/river-stone.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
          )
        }
      }
      moonGlow: file(
        relativePath: { eq: "pre-finished-wood-panels/ridgemont-collection/moon-glow.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
          )
        }
      }
      summitShadow: file(
        relativePath: { eq: "pre-finished-wood-panels/ridgemont-collection/summit-shadow.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
          )
        }
      }
      umbraDust: file(
        relativePath: { eq: "pre-finished-wood-panels/ridgemont-collection/umbra-dust.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
          )
        }
      }
      highDesert: file(
        relativePath: { eq: "pre-finished-wood-panels/ridgemont-collection/high-desert.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
          )
        }
      }
    }
  `);

  const path = ["Pre-finished Wood Panels in Southern California"];
  return (
    <Layout>
      <div className="w-full max-w-full relative overflow-hidden">
        <HeadData 
          title="Pre-finished Wood Panels - So Cal"
          description="Inspired by the untamed beauty of nature, Pre-finished Wood Panels are the pinnacle of understated sophistication." 
        />
        <Header />
        <TitleSubDiv
          parentText="Products"
          title="Pre-finished Wood Panels in Southern California"
          className="mb-8"
          path={path}
        />
        <ContentContainer
          className="w-full max-w-content md:mx-5 flex items-center justify-center"
          opacityClassName="bg-opacity-60"
          image={heroImage.backGround}
        >
          <div className="text-white my-20 text-center text-3xl gap-y-4 w-full max-w-2xl">
            <p className="font-bold">Ridgemont Collection of Pre-finished Wood Panels from States+</p>
            <p>Manufactured in the United States</p>
            <p className="text-base pt-5">
              Available in 6 stunning colors with rich depth and style derived from nature.
            </p>
            <ButtonAction to="/contact" className="mt-10 bg-actionBlue px-16">
              Request free quote
            </ButtonAction>
          </div>
        </ContentContainer>

        <ContentContainerNew
          containerClassName="w-full mt-10 md:mt-24"
          className="flex flex-col px-0 md:px-4 w-full max-w-7xl"
        >
          <div className="flex flex-col sm:flex-row">
            <div className="w-full sm:w-7/12 p-5 sm:pl-0 sm:pr-8">
              <p className="text-3xl font-bold pb-12 text-center md:text-left">
                Ridgemont Collection from States+
              </p>
              <p className="mb-6">
                Like all of States Industries panels, the Ridgemont collection of Rift white oak, slip matched, pre-finished wood panels are of the highest quality.
              </p>
              <p className="mb-6">
                These panels have an MDF core,  2S finish, wire brushed face. And they are  scratch and stain resistant.
              </p>
              <p className="">These panels are manufactured in the U.S. where they are rigorously tested for quality assurance, ensuring the best product possible.
              </p>
            </div>
            <div className="w-full sm:w-5/12">
              <StaticImage
                src="../../../images/pre-finished-wood-panels/ridgemont-collection-page-section-new.png"
                alt="Ridgemont Collection"
                placeholder="blurred"
                quality={100}
                layout="constrained"
              />
            </div>
          </div>
        </ContentContainerNew>

        <ContentContainer className="w-full max-w-content mt-8 md:mt-16">
          <h2 className="text-center bg-gray1 uppercase py-8">Collection - 6 Colors</h2>
          <div className="grid grid-cols-1 lg:grid-cols-6 gap-2 md:gap-5 sm:gap-11 mt-0 md:mt-8">            

            <ContentContainerNew
              className="py-24 md:py-48 flex items-center"
              image={heroImage.sandDrift}
            >
              <p className="bg-actionBlue text-white px-6 py-3 text-lg text-center">
                Sand Drift
              </p>
            </ContentContainerNew>
          
            <ContentContainerNew
              className="py-24 md:py-48 flex items-center"
              image={heroImage.riverStone}
            >
              <p className="bg-actionBlue text-white px-6 py-3 text-lg text-center">
                River Stone
              </p>
            </ContentContainerNew>
          
            <ContentContainerNew
              className="py-24 md:py-48 flex items-center"
              image={heroImage.moonGlow}
            >
              <p className="bg-actionBlue text-white px-6 py-3 text-lg text-center">
                Moon Glow
              </p>
            </ContentContainerNew>

            <ContentContainerNew
              className="py-24 md:py-48 flex items-center"
              image={heroImage.summitShadow}
            >
              <p className="bg-actionBlue text-white px-6 py-3 text-lg text-center">
                Summit Shadow
              </p>
            </ContentContainerNew>
          
            <ContentContainerNew
              className="py-24 md:py-48 flex items-center"
              image={heroImage.umbraDust}
            >
              <p className="bg-actionBlue text-white px-6 py-3 text-lg text-center">
                Umbra Dust
              </p>
            </ContentContainerNew>

            <ContentContainerNew
              className="py-24 md:py-48 flex items-center"
              image={heroImage.highDesert}
            >
              <p className="bg-actionBlue text-white px-6 py-3 text-lg text-center">
                High Desert
              </p>
            </ContentContainerNew>
          </div>

          <div className="w-full flex flex-col items-center justify-center">
            <p className="text-center text-lg md:text-xl mt-8 mb-6">EDGEBANDING INFORMATION:</p>
            <ul className="list-disc">
              <li>PVC Edgebanding .5mm x 15/16”	600 lf rolls</li>
              <li>Veneer Edgebanding .5 mm x 7/8”	500 lf rolls</li>
            </ul>
            <p className="text-center text-lg md:text-xl mt-8 mb-6">SIZES AVAILABLE:</p>
            <ul className="list-disc">
              <li>¼“ x 49” x 97”</li>
              <li>¾” x 49” x 97”</li>
            </ul>
          </div>
          <div className="w-auto mx-auto">
            <a href="https://statesdev.wpenginepowered.com/ridgemont-collection/" target="_blank" rel="noopener noreferrer">
              <p className="bg-actionBlue text-white px-8 py-3 text-lg rounded-full text-center mt-8">
                More Information: States – Ridgemont Collection
              </p>
            </a>
          </div>
        </ContentContainer>

        <ContentContainer className="w-full max-w-content mt-16 mb-6">
          <div className="bg-gray2 text-center flex flex-col gap-y-10 py-10 sm:py-24 px-5 xl:px-102 text-lg md:text-xl">
            <i>
              “Inspired by the untamed beauty of nature, the Ridgemont Collection is the pinnacle of understated sophistication. Our meticulous wire-brushing process enhances the natural beauty of the wood grain, creating a stunning product that elevates any interior space or furniture design. Manufactured in the United States, Ridgemont brushed veneer panels can be customized with a variety of stunning colors and finishes to meet your exact specifications.<br/>Discover the beauty of the Ridgemont Collection today.”
            </i>
            <p>- States+</p>
          </div>
        </ContentContainer>

        <ContactExpert />
      </div>
    </Layout>
  );
};

export default PreFinishedWoodPanels;
